import config from '../../public/config.json';

class Path {
  static getBase() {
    const _config: any = config;
    return _config[import.meta.env.MODE]['base'];
  }
}

export default Path;
