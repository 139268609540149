class Server {
  config: any = {};

  constructor(config: any) {
    this.config = config;
  }

  getConfig(env: string) {
    let res;
    let dynamic = true;
    for (const key in this.config) {
      if (env === key) {
        dynamic = false;
      }
    }

    // 静态配置
    if (!dynamic) {
      res = this.config[env];
    }
    // 动态配置
    else {
      const location = window.location;
      res = {
        protocol: location.protocol.split(':')[0],
        ip: location.hostname,
        port: location.port,
      }
    }
    return res;
  }
}

export default Server;
