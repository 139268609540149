<template>
  <el-drawer v-model="DrawerVisible" :destroy-on-close="true" class="DrawerStyle" @close="closeFn">
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <template #default>
      <!-- 内容 -->
      <div class="content">
        <el-form :model="ruleForm" ref="ruleFormRef" :rules="rules" size="default">

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="角色名称：" :label-width="formLabelWidth" prop="roleName">
                <el-input v-model="ruleForm.roleName" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="权限描述：" :label-width="formLabelWidth" prop="remark">
                <el-input type="textarea" :maxlength="150" show-word-limit :rows="3" v-model="ruleForm.remark"
                  autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="20" style="margin-left: 0;">
          <span class="title">系统权限设置</span>
        </el-row>
        <el-scrollbar style="height: calc(100vh - 22rem)">
          <div class="rolePrivileges" v-for="(item, ind) in state.privilegeList" :key="ind">
            <div class="header" :style="item.treeShow ? '' : 'border:none'">
              {{ item.label }}
              <img :src="Asset.getAssetsFile('platform/down.png')" @click="treeShow(item)"
                :class="item.treeShow ? '' : 'roteShow'">
            </div>
            <el-tree v-show="item.treeShow" :data="item.children" :props="defaultProps" default-expand-all
              ref="treeBox" node-key="id" :show-checkbox="true" @check="getCheckedKeys" :default-checked-keys="state.setCheckId"/>
          </div>
        </el-scrollbar>
      </div>
    </template>
    <template #footer>
      <div v-if="title != `查看详情`">
        <el-button color="#417FF9" @click="confirmClick(ruleFormRef)">提交</el-button>
        <el-button class="restBtn" @click="resetForm(ruleFormRef)">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import Asset from '@/utils/Asset';
import { FormInstance, FormRules } from 'element-plus';
import { treeselect } from '@/api/platform'
const props = defineProps({
  ruleForm: {
    type: Object, default: {
      roleName: '',
      remark: '',
    }
  },
  isreadonly: Boolean,
  title: String,
})
const state = reactive({
  privilegeList: [],
  setCheckId: []
})
const disabledFn = () => {
  if (props.title == `查看详情`) {
    return true
  } else {
    return false
  }
}

const defaultProps = {
  children: 'children',
  label: 'label',
  disabled: disabledFn,
}
const treeBox = ref(null)
const formLabelWidth = '100px'
const ruleFormRef = ref<FormInstance>()
const DrawerVisible = ref(false)
const otherInfo = ref([])
const rules = reactive<FormRules>({
  roleName: [
    {
      required: true,
      message: '请填写角色名称',
      trigger: 'blur',
    },
  ],
  remark: [
    {
      required: true,
      message: '请填写角色描述备注',
      trigger: 'blur',
    },
  ],
})

const emit = defineEmits(["SetFormData"])

const confirmClick = async (formEl: FormInstance | undefined) => {
  // console.log(props.ruleForm, formEl, '5');
  console.log(otherInfo.value, 's');

  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {

      emit('SetFormData', props.ruleForm, otherInfo.value)
      resetForm(formEl)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const treeShow = (item) => {
  item.treeShow = !item.treeShow
}

const OpenDraw = () => {
  DrawerVisible.value = true
  ruleFormRef.value?.resetFields()
}
const closeFn = () =>{
  state.setCheckId = []
  DrawerVisible.value = false
}

const resetForm = (formEl: FormInstance | undefined) => {
  formEl && formEl.resetFields()
  DrawerVisible.value = false
  state.setCheckId = []
}

const TreeFn = () => {
  treeselect().then((res) => {
    if (res.code == 200) {
      res.data.map(item => {
        item.treeShow = true
      })
      state.privilegeList = res.data
    }
  })
}



function findParentId(data, childId, _parentId?) {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.children && item.children.some(child => child.id === childId)) {
      return item.id;
    } else if (item.children) {
      const result = findParentId(item.children, childId, item.id);
      if (result) {
        return result;
      }
    }
  }
  return;
}

const getCheckedKeys = (_menuIds1) => {

  const menuList = []
  var newArry = []

  treeBox.value.forEach(v => {
    let newArr
    // =v.getCheckedNodes(false, true)
    if (v.getCheckedNodes(false, true).length > 0) {
      newArr = [...v.getCheckedNodes(false, true).map(k => k.id), findParentId(state.privilegeList, v.getCheckedNodes(false, true)[0].id)]
    } else[
      newArr = []
    ]
    menuList.push(newArr)
  })
  menuList.map(item => newArry = newArry.concat(item))
  otherInfo.value = newArry
}
const setCheckedKeys = (val) => {
  console.log('查看',val);
  
  state.setCheckId = val
}


onMounted(() => {
  TreeFn()
})

defineExpose({
  OpenDraw,
  setCheckedKeys
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
  color: #000000;
}

.title {
  color: #5087FF;
  margin-bottom: 18px;
}
</style>