
<template>
  <Search :searchList="state.searchList" @getRuleForm="SearchFn" />
  <el-card class="ContaintCard">
    <Title :TitleName="'字典管理'">
      <template #btn>
        <div>
          <el-button color="#0D56CC" :icon="Plus" @click="OpenDraw">新增</el-button>
        </div>
      </template>
    </Title>
    <Table :state="state" @changePage="changePage" @handleSizeChange="handleSizeChange"
      :defaultheight="'calc(100vh - 27rem)'">
      <template #column>
        <el-table-column prop="dictCode" label="字典编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dictLabel" label="字典标签" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="dictValue" label="字典键值" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="dictSort" label="字典排序" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template #default="scope">
            <span :class="scope.row.status == 0 ? 'normal' : 'outage'" class="spanTable">{{
              scope.row.status == 0
              ? '正常'
              : scope.row.status == 1
                ? '停用'
                : '未知状态'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-for="button in buttons" :key="button.text" :type="button.type" link
              @click="button.fun(scope.$index, scope.row)">{{ button.text
              }}</el-button>
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
  <DictDraw ref="dictdraw" :title="state.title" :ruleForm="state.ruleForm" @SetFormData="addForm" />
</template>

<script setup lang="ts">
import Search from './components/Search/DictdataSearch.vue'
import Table from '@/components/Table.vue'
import Title from '@/components/Title.vue'
import DictDraw from './components/Draw/DictdataDraw.vue'
import { Plus } from '@element-plus/icons-vue'
import { AddDictDataInfo, GetDictDataInfo, EditDictDataInfo, DelDictDataId } from '@/api/dictory'
import { EpPropMergeType } from 'element-plus/es/utils/vue/props/types'
import { confirmDelete } from '@/utils/messagebox.ts'
import { useRoute } from 'vue-router'
interface Button {
  type: EpPropMergeType<
    StringConstructor,
    'primary' | 'danger',
    unknown
  >
  text: string
  fun: Function
}

const dictdraw = ref(null)
const route = useRoute()
const state = reactive({
  searchList: {
    'status': '状态',
    'dictLabel': '字典标签'
  },
  tableData: [],
  total: 20, // 总条数
  currentPage: 1, // 当前页
  pageSize: 8, // 分页大小
  title: '新增系统参数',
  ruleForm: {
    dictType: route.params.dictType,
    dictLabel: '',
    dictValue: '',
    dictSort: '',
    listClass: '',
    cssClass: '',
    remark: '',
    status: '',
  },
})

const edit = async (_ind, val) => {
  state.title = '编辑系统参数'
  dictdraw.value.OpenDraw()
  state.ruleForm = { ...val }
}
const del = (_ind, val) => {
  confirmDelete(async () => {
    const res = await DelDictDataId(val.dictCode)
    if (res.code == 200) {
      getInfo()
      ElMessage({ type: 'success', message: '删除成功' })
    } else {
      ElMessage({ type: 'error', message: '删除失败' })
    }
  })
}
const buttons: Button[] = [
  { type: 'primary', text: '编辑', fun: edit },
  { type: 'danger', text: '删除', fun: del },
]

// 获取表单信息
const getInfo = async (params?) => {
  console.log(route, 'rouset');

  let res = await GetDictDataInfo({ pageSize: state.pageSize, pageNum: state.currentPage, ...params, dictType: route.params.dictType })
  if (res.code == 200) {
    state.tableData = res.rows
    state.total = res.total
  }
}

const changePage = (val) => {
  state.pageSize = val
  getInfo()
}
const handleSizeChange = (val) => {
  state.currentPage = val
  getInfo()
}
// 查询
const SearchFn = (val) => {
  getInfo(val)
}
// 新增
const OpenDraw = () => {
  state.title = '新增系统参数'
  state.ruleForm = {
    dictType: route.params.dictType,
    dictLabel: '',
    dictValue: '',
    dictSort: '',
    listClass: '',
    cssClass: '',
    remark: '',
    status: '',
  }
  dictdraw.value.OpenDraw()
}

// 新增表单
const addForm = async (_val) => {

  if (state.title !== '编辑系统参数') {
    const res = await AddDictDataInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '新增成功' })
    } else {
      ElMessage({ type: 'error', message: '新增失败' })
    }
    getInfo()
  } else {
    const res = await EditDictDataInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '修改成功' })
    } else {
      ElMessage({ type: 'error', message: '修改失败' })
    }
    getInfo()
  }
}
onMounted(() => {
  getInfo()
})
</script>
<style lang="scss" scoped>
.spanTable {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: -35%;
    width: 8px;
    height: 8px;
    border-radius: 50px 50px 50px 50px;
  }
}

.normal {
  &::after {
    background: #31a852;
  }
}

.outage {
  &::after {
    background: #ea4132;
  }
}
</style>