import { request } from '@/request/http';


export const AddSysaInfo = (params) => request({
  url: '/system/config',
  method: 'POST',
  params
})

export const GetSysaInfo = (params) => request({
  url: '/system/config/list',
  method: 'GET',
  params
})



export const DelSysaId = (params) => request({
  url: `/system/config`,
  method: 'DELETE',
  params
})


export const EditSysaInfo = (params) => request({
  url: '/system/config',
  method: 'PUT',
  params
})

export const clearCache = () => request({
  url: '/system/config/clearCache',
  method: 'DELETE',
})
