import { request } from '@/request/http';


export const AddMenuInfo = (params) => request({
    url: '/system/menu',
    method: 'POST',
    params
  })
  
export const GetMenuInfo = (params) => request({
    url: '/system/menu/list',
    method: 'GET',
    params
  })

export const GetMenuTree = () => request({
    url: `/system/menu/treeselect?tenantMenu=0`,
    method: 'GET'
  })
  
  
  
export const DelMenuId = (params) => request({
    url: `/system/menu`,
    method: 'DELETE',
    params
  })
  

  export const EditMenuInfo = (params) => request({
    url: '/system/menu',
    method: 'PUT',
    params
  })
  

  export const GetTenantMenuTree = () => request({
    url: `/system/menu/treeselect?tenantMenu=1`,
    method: 'GET'
  })
  
  