import RolePower from '../views/platform/RolePower.vue'
import UsersList from '../views/platform/UsersList.vue'
import Content from '@/layers/Content/index.vue';
import TenantList from '@/views/tenant/TenantUser.vue'
import TenantRole from '@/views/tenant/TenantRole.vue'
import TenantMenu from '@/views/tenant/TenantMenu.vue'
export default [
  {
    path: '/platform',
    name: '平台管理',
    redirect: '/platform/rolepower',
    component: Content,
    children: [
      {
        path: 'rolepower',
        name: 'rolepower',
        component: RolePower,
        meta: {
          requiresAuth: true,
          routeName: '角色权限'
        },
      },
      {
        path: 'userslist',
        name: 'userslist',
        component: UsersList,
        meta: {
          requiresAuth: true,
          routeName: '用户列表'
        },
      },
    ]
  },
  {
    path: '/tenant',
    name: '平台租户',
    redirect: '/tenant/tenantlist',
    component: Content,
    children: [
      {
        path: 'tenantlist',
        name: 'tenantlist',
        component: TenantList,
        meta: {
          requiresAuth: true,
          routeName: '租户列表'
        },
      },
      {
        path: 'tenantmenu',
        name: 'tenantmenu',
        component: TenantMenu,
        meta: {
          requiresAuth: true,
          routeName: '租户菜单设置'
        },
      },
      {
        path: 'tenantrole',
        name: 'tenantrole',
        component: TenantRole,
        meta: {
          requiresAuth: true,
          routeName: '租户角色管理'
        },
      }
    ]
  }
]