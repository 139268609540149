<template>
  <el-drawer v-model="DrawerVisible" :destroy-on-close="true" class="DrawerStyle">
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <template #default>
      <!-- 内容 -->
      <div class="content">
        <el-form :model="ruleForm" ref="ruleFormRef" :rules="rules">

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="登录账号：" :label-width="formLabelWidth" prop="userName">
                <el-input v-model="ruleForm.userName" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="title == '新增用户'">
            <el-col :span="24">
              <el-form-item label="登录密码：" :label-width="formLabelWidth" prop="password">
                <el-input v-model="ruleForm.password" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="用户姓名：" :label-width="formLabelWidth" prop="nickName">
                <el-input v-model="ruleForm.nickName" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="手机号：" :label-width="formLabelWidth" prop="phonenumber">
                <el-input v-model="ruleForm.phonenumber" autocomplete="off" :readonly="isreadonly"  />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="岗位：" :label-width="formLabelWidth" prop="post">
                <el-input v-model="ruleForm.post" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="账户类型：" :label-width="formLabelWidth" prop="roleIds">
                <el-select multiple clearable v-model="ruleForm.roleIds" placeholder="选择类型">
                  <el-option v-for="item in OptionsMenuId" :key="item.roleId" :label="item.roleName" :value="item.roleId " />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="状态：" :label-width="formLabelWidth" prop="status">
                <el-select clearable v-model="ruleForm.status" placeholder="选择状态">
                  <el-option label="启用" :value="0" />
                  <el-option label="停用" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-form>
      </div>
    </template>
    <template #footer>
      <div>
        <el-button color="#417FF9" @click="confirmClick(ruleFormRef)">提交</el-button>
        <el-button class="restBtn" @click="resetForm(ruleFormRef)">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { FormInstance, FormRules } from 'element-plus';

interface OptionMenuId {
  roleId: string
  roleName: string
}

const props = defineProps({
  ruleForm: {
    type: Object, default: {
      nickName: '',
      phonenumber: '',
      post: '',
      userName: '',
      roleIds: [],
    }
  },
  isreadonly: Boolean,
  title: String,
  OptionsMenuId: Array as () => Array<OptionMenuId>
})
watch(
  props.OptionsMenuId,
  (newV,oldV)=>{
    console.log(newV,oldV,'新');
    
  },{
    immediate:true
  }
)


const formLabelWidth = '100px'
const ruleFormRef = ref<FormInstance>()
const DrawerVisible = ref(false)
const rules = reactive<FormRules>({
  userName: [
    {
      required: true,
      message: '请填写登录账号',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '请填写登录密码',
      trigger: 'blur',
    },
  ],
  nickName: [
    {
      required: true,
      message: '请填写用户姓名',
      trigger: 'blur',
    },
  ],
  phonenumber: [
    {
      required: true,
      message: '请填写手机号',
      trigger: 'blur',
    },
  ],
  roleIds: [
    {
      required: true,
      message: '请选择账户类型',
      trigger: 'change',
    },
  ],
})

const emit = defineEmits(["SetFormData"])

const confirmClick = async (formEl: FormInstance | undefined) => {
  console.log(props.OptionsMenuId, formEl, '5');
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {

      emit('SetFormData', props.ruleForm)
      resetForm(formEl)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const OpenDraw = () => {
  DrawerVisible.value = true
  // ruleFormRef.value?.resetFields()
}

const resetForm = (formEl: FormInstance | undefined) => {
  formEl && formEl.resetFields()
  DrawerVisible.value = false
}




onMounted(() => {
})

defineExpose({
  OpenDraw,
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
  color: #000000;
}

.title {
  color: #5087FF;
}
</style>