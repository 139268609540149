<template>
    <el-drawer v-model="drawer" size="calc(580 / 1920 * 100vw)" class="DrawerStyle">
        <template #header>
            <h4>租户信息</h4>
        </template>
        <div class="cardPart">
            <div class="partOne">
                <span style="display: inline-block; width: calc(100 / 1920 * 100vw)">账户类型：</span>
                <span style="color: #000000">{{
                    InfoData.memberType == 1 ? '企业用户' : '个人用户'
                }}</span>
            </div>
            <el-divider class="line" />
            <div class="partOne">
                <span style="display: inline-block; width: calc(100 / 1920 * 100vw)">角色：</span>
                <span style="color: #000000">{{ InfoData.roleNames }}</span>
            </div>
            <el-divider class="line" />
            <div class="partOne">
                <span style="display: inline-block; width: calc(100 / 1920 * 100vw)">姓名：</span>
                <span style="color: #000000">{{ InfoData.contact }}</span>
            </div>
            <el-divider class="line" />
            <div class="partOne">
                <span style="display: inline-block; width: calc(100 / 1920 * 100vw)">手机号：</span>
                <span style="color: #000000">{{ InfoData.phone }}</span>
            </div>
            <el-divider class="line" />
            <div class="partOne">
                <span style="display: inline-block; width: calc(100 / 1920 * 100vw)">邮箱：</span>
                <span style="color: #000000">{{ InfoData.email }}</span>
            </div>
            <el-divider class="line" />
            <div class="partOne">
                <span style="display: inline-block; width: calc(100 / 1920 * 100vw)">状态：</span>
                <span style="color: #000000" v-show="InfoData.status">{{
                    InfoData.status == 0 ? '正常' : '禁用'
                }}</span>
            </div>
        </div>
    </el-drawer>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
defineProps({
    InfoData: Array as any
})
const drawer = ref(false)
const OpenDraw = () => {
    drawer.value = true
}

defineExpose({
    OpenDraw,
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
    color: #000000;
}

.title {
    color: #5087FF;
}

.cardPart {
    width: calc(522 / 1920 * 100vw);
    background: #fcfdff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: calc(1 / 1920 * 100vw) solid #cacfdc;
    margin-top: calc(16 / 1920 * 100vw);

    .partOne {
        padding: 19px 32px 17px 32px;
        font-size: 14px;
        color: #6b7180;
    }

    .line {
        width: calc(458 / 1920 * 100vw);
        margin : 0;
        margin-left: calc(32 / 1920 * 100vw);
        opacity: 1;
        border-color:  #ebeef5;
        padding: 0;
    }
}
</style>