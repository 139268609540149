import { request } from '@/request/http';



export const GetOperlogInfo = (params) => request({
    url: '/monitor/operlog/list',
    method: 'GET',
    params
  })
  
  
  
export const DelOperlogId = (params) => request({
    url: `/monitor/operlog`,
    method: 'DELETE',
    params
  })
  


  