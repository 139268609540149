

<template>
    <el-card class="ContaintCard">
      <Title>
        <template #btn>
          <div>
            <el-button color="#0D56CC" :icon="Plus" @click="OpenDraw">新增</el-button>
          </div>
        </template>
      </Title>
      <Table :state="state" @changePage="changePage" @handleSizeChange="handleSizeChange">
        <template #column>
          <!-- <el-table-column type="selection"></el-table-column> -->
          <el-table-column prop="roleName" label="名称">
          </el-table-column>
          <el-table-column prop="remark" label="权限">
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button v-for="button in buttons" :key="button.text" :type="button.type" link
                @click="button.fun(scope.$index, scope.row)">{{ button.text
                }}</el-button>
            </template>
          </el-table-column>
        </template>
      </Table>
    </el-card>
    <RoleDraw ref="roledraw" :title="state.title" :ruleForm="state.ruleForm" :isreadonly="state.isreadonly"
      @SetFormData="addForm" />
  </template>
  
  <script setup lang="ts">
  import Title from '@/components/Title.vue'
  import Table from '@/components/Table.vue'
  import RoleDraw from './components/Draw/RoleDraw.vue'
  import { AddRoleInfo, GetRoleInfo, GetRoleId, EditRoleInfo, DelRoleId } from '@/api/role'
  import { Plus } from '@element-plus/icons-vue'
  import { EpPropMergeType } from 'element-plus/es/utils/vue/props/types'
  
  import { confirmDelete } from '@/utils/messagebox.ts'
  interface Button {
    type: EpPropMergeType<
      StringConstructor,
      'primary' | 'danger',
      unknown
    >
    text: string
    fun: Function
  }
  
  
  const roledraw = ref(null)
  
  const state = reactive({
    tableData: [],
    total: 20, // 总条数
    currentPage: 1, // 当前页
    pageSize: 8, // 分页大小
    title: '新增角色',
    ruleForm: {
      roleName: '',
      remark: '',
      roleKey: '',
      status: '',
      menuIds: [],
      roleId: '',
      tenantRole:'1'
    },
    EditMenuId: [],
    isreadonly: false,
    roleId: null,
  })
  
  const look = async (_ind, val) => {
    state.roleId = null
    state.title = '查看详情'
    roledraw.value.OpenDraw()
    state.isreadonly = true
    state.ruleForm = { ...val }
    // 获取对应角色权限数组
    const res = await GetRoleId(val.roleId)
    roledraw.value.setCheckedKeys(res.data.menuIds)
  }
  const edit = async (_ind, val) => {
    state.title = '编辑角色'
    roledraw.value.OpenDraw()
    state.ruleForm = { ...val }
    state.roleId = val.roleId
    state.isreadonly = false
    // 获取对应角色权限数组
    const res = await GetRoleId(val.roleId)
    roledraw.value.setCheckedKeys(res.data.menuIds)
  }
  const del = (_ind, val) => {
    confirmDelete(async () => {
      const res = await DelRoleId(val.roleId)
      if (res.code == 200) {
        getInfo()
        ElMessage({ type: 'success', message: '删除成功' })
      } else {
        ElMessage({ type: 'error', message: '删除失败' })
      }
    })
  }
  const buttons: Button[] = [
    { type: 'primary', text: '查看详情', fun: look },
    { type: 'primary', text: '编辑', fun: edit },
    { type: 'danger', text: '删除', fun: del },
  ]
  
  // 新增
  const OpenDraw = () => {
    state.isreadonly = false
    state.title = '新增角色'
    state.ruleForm = {
      roleName: '',
      remark: '',
      roleKey: '',
      status: '',
      menuIds: [],
      roleId: '',
      tenantRole:'1'
    }
    state.roleId = null
    roledraw.value.OpenDraw()
  }
  // 生成uuid
  const uuid = () => {
    let s = [];
    let hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    let uuid = s.join('');
    return uuid
  }
  // 获取表单信息
  const getInfo = async () => {
    let res = await GetRoleInfo({ pageSize: state.pageSize, pageNum: state.currentPage ,tenantRole:'1'})
    if (res.code == 200) {
      state.tableData = res.rows
      state.total = res.total
    }
  }
  const changePage = (val) => {
    state.pageSize = val
    getInfo()
  }
  const handleSizeChange = (val) => {
    state.currentPage = val
    getInfo()
  }
  
  // 新增表单
  const addForm = async (val, menuIds) => {
    state.ruleForm = {
      roleName: val.roleName,
      remark: val.remark,
      roleKey: uuid(),
      status: '0',
      roleId: state.roleId,
      menuIds,
      tenantRole:'1'
    }
  console.log(menuIds,state.ruleForm,'shuju');
    if (state.title !== '编辑角色') {
      const res = await AddRoleInfo(state.ruleForm)
      if (res.code == 200) {
        ElMessage({ type: 'success', message: '新增成功' })
      } else {
        ElMessage({ type: 'error', message: '新增失败' })
      }
      getInfo()
    } else {
      const res = await EditRoleInfo(state.ruleForm)
      if (res.code == 200) {
        ElMessage({ type: 'success', message: '修改成功' })
      } else {
        ElMessage({ type: 'error', message: '修改失败' })
      }
      getInfo()
    }
  }
  
  onMounted(() => {
    getInfo()
  })
  defineProps<{ msg: string }>()
  
  </script>
  <style lang="scss" scoped></style>
  