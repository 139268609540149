import { request } from '@/request/http';


export const AddDictDataInfo = (params) => request({
    url: '/system/dict/data',
    method: 'POST',
    params
  })
  
export const GetDictDataInfo = (params) => request({
    url: `/system/dict/data/list`,
    method: 'GET',
    params
  })
  
  
  
export const DelDictDataId = (params) => request({
    url: `/system/dict/data`,
    method: 'DELETE',
    params
  })
  

  export const EditDictDataInfo = (params) => request({
    url: '/system/dict/data',
    method: 'PUT',
    params
  })
  