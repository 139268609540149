<template>
  <el-card class="SearchBox">
    <el-form ref="ruleFormRef" :model="ruleForm" label-width="90px" class="search-ruleForm" size="default" status-icon>
      <el-form-item :label="searchList['configType']" prop="configType">
        <el-select clearable v-model="ruleForm.configType" placeholder="选择系统内置">
          <el-option label="是" value="Y" />
          <el-option label="否" value="N" />
        </el-select>
      </el-form-item>
      <el-form-item :label="props.searchList['configName']" prop="configName">
        <el-input clearable v-model="ruleForm.configName" type="text" placeholder="输入参数名称"/>
      </el-form-item>
      <el-form-item :label="props.searchList['configKey']" prop="configKey">
        <el-input clearable v-model="ruleForm.configKey" type="text"  placeholder="输入参数key"/>
      </el-form-item>
      <el-button color="#4886FF" :icon="Search" @click="submitForm(ruleFormRef)">搜索</el-button>
      <el-button color="#f1f2f4" class="RefreshLeft" :icon="RefreshLeft" @click="resetForm(ruleFormRef)">重置</el-button>
    </el-form>
  </el-card>
</template>
  
<script lang="ts" setup>
import { Search, RefreshLeft } from '@element-plus/icons-vue'
import { reactive, ref } from 'vue'
import type { FormInstance } from 'element-plus'

interface RuleForm {
  configType: string
  configName: string
  configKey: string
}

const props = defineProps({
  searchList: {
    type: Object, default: {
      'configType': '系统内置',
      'configName': '参数名称',
      'configKey': '参数key'
    }
  },
})

const emit = defineEmits(["resetForm", "getRuleForm"])

const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
  configType: "",
  configName: "",
  configKey: ''
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      emit('getRuleForm', ruleForm)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.resetFields()
  emit('getRuleForm', ruleForm)
}


</script>
  
<style lang="scss" scoped>
.SearchBox {
  margin: 16px 0;

  .el-input {
    width: 190px;
  }

  ::v-deep .el-form-item--default .el-form-item__label {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
  }

  .search-ruleForm {
    display: flex;
    align-items: center;

    .el-form-item {
      margin-bottom: 0;
    }

    .el-button {
      width: 72px;
      height: 32px;
      color: #FFF;
      margin-left: 32px;
      font-size: 15px;
    }

    ::v-deep .el-date-editor.el-input__wrapper {
      width: 200px !important;
    }
  }
}
</style>