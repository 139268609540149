import { defineStore } from 'pinia';
import localforage from 'localforage';

const useUserStore = defineStore({
  id: 'user',

  state: () => {
    return {
      _baseInfo: {},
      _routesList: [],
    };
  },

  getters: {
    // 无参，直接返回值
    baseInfo(state) {
      return state._baseInfo;
    },
    routesList(state) {
      return state._routesList;
    },
    token() {
      return localforage.getItem('token');
    },
  },

  actions: {
    // 设置路由，菜单中使用到
    setRoutesList(data) {
      this._routesList = data;
    },
    setBaseInfo(data) {
      this._baseInfo = data;
    },
    setToken(token) {
      return new Promise((success) => {
        success(localforage.setItem('szhlsn_token', token));
      });
    },
  },
});

export default useUserStore;
