<template>
  <el-card class="SearchBox">
    <el-form ref="ruleFormRef" :model="ruleForm" label-width="90px" class="search-ruleForm" size="default" status-icon>
      <el-form-item :label="searchList['roleId']" prop="roleId">
        <el-select  clearable v-model="ruleForm.roleId" placeholder="选择类型">
          <el-option v-for="item in OptionsMenuId" :key="item.roleId" :label="item.roleName" :value="item.roleId" >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="props.searchList['status']" prop="status">
        <el-select clearable v-model="ruleForm.status" placeholder="选择状态">
          <el-option label="正常" value="0" />
          <el-option label="停用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item :label="props.searchList['phonenumber']" prop="phonenumber">
        <el-input clearable v-model="ruleForm.phonenumber" type="text" placeholder="输入手机号查询" />
      </el-form-item>
      <el-button color="#4886FF" :icon="Search" @click="submitForm(ruleFormRef)">搜索</el-button>
      <el-button color="#f1f2f4" class="RefreshLeft" :icon="RefreshLeft" @click="resetForm(ruleFormRef)">重置</el-button>
    </el-form>
  </el-card>
</template>

<script lang="ts" setup>
import { Search, RefreshLeft } from '@element-plus/icons-vue'
import { reactive, ref } from 'vue'
import type { FormInstance } from 'element-plus'

interface RuleForm {
  roleId: string
  status: string
  phonenumber: string
}

interface OptionMenuId {
  roleId: string
  roleName: string
}

const props = defineProps({
  searchList: {
    type: Object, default: {
      'roleId': '账户类型',
      'status': '状态',
      'phonenumber': '手机号'
    }
  },
  OptionsMenuId: Array as () => Array<OptionMenuId>
})

const emit = defineEmits(["resetForm", "getRuleForm"])

const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
  status: "",
  roleId: '',
  phonenumber: ''
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      emit('getRuleForm', ruleForm)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.resetFields()
  
  emit('getRuleForm', ruleForm)
}


</script>

<style lang="scss" scoped>
.SearchBox {
  margin: 16px 0;

  .el-input {
    width: 190px;
  }

  ::v-deep .el-form-item--default .el-form-item__label {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
  }

  .search-ruleForm {
    display: flex;
    align-items: center;

    .el-form-item {
      margin-bottom: 0;
    }

    .el-button {
      width: 72px;
      height: 32px;
      color: #FFF;
      margin-left: 32px;
      font-size: 15px;
    }

    ::v-deep .el-date-editor.el-input__wrapper {
      width: 200px !important;
    }
  }
}</style>