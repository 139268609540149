
<template>
  <Search :searchList="state.searchList" :OptionsMenuId="state.OptionsMenuId" @getRuleForm="SearchFn"
    @setTime="changeTime" />
  <el-card class="ContaintCard">
    <Title :TitleName="'操作日志'">
    </Title>
    <Table :state="state" @changePage="changePage" @handleSizeChange="handleSizeChange"
      :defaultheight="'calc(100vh - 27rem)'">
      <template #column>
        <el-table-column prop="operId" label="序号"></el-table-column>
        <el-table-column prop="operName" label="操作人员" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="roleNames" label="角色名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="operTime" label="操作时间">
        </el-table-column>
        <el-table-column prop="operIp" label="IP地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="jsonResult" label="操作记录" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-for="button in buttons" :key="button.text" :type="button.type" link
              @click="button.fun(scope.$index, scope.row)">{{ button.text
              }}</el-button>
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
  <OperaDraw ref="operadraw" :LoginLogdata="state.ruleForm" />
</template>

<script setup lang="ts">
import Search from './components/Search/OperaSearch.vue'
import Table from '@/components/Table.vue'
import Title from '@/components/Title.vue'
import OperaDraw from './components/Draw/OperaDraw.vue'
import { GetOperlogInfo, DelOperlogId } from '@/api/operalog'
import { EpPropMergeType } from 'element-plus/es/utils/vue/props/types';
import { GetRoleList } from '@/api/user'
import { addDateRange } from '@/utils/dataTime'
import { confirmDelete } from '@/utils/messagebox.ts'
interface Button {
  type: EpPropMergeType<
    StringConstructor,
    'primary' | 'danger',
    unknown
  >
  text: string
  fun: Function
}

const state = reactive({
  searchList: {
    'operName': '操作人',
    'roleId': '角色名称',
    'operTime': '操作时间'
  },
  tableData: [],
  total: 20, // 总条数
  currentPage: 1, // 当前页
  pageSize: 8, // 分页大小
  ruleForm: {
    nickName: '',
    phonenumber: '',
    post: '',
    userName: '',
    roleIds: [],
  },
  OptionsMenuId: [] as any[],
  isreadonly: false,
  dateRange: []
})
const operadraw = ref(null)
const look = async (_ind, val) => {
  state.ruleForm = { ...val }
  operadraw.value.handleOpen()
}
const del = (_ind, val) => {
  confirmDelete(async () => {
    const res = await DelOperlogId(val.operId)
    if (res.code == 200) {
      getInfo()
      ElMessage({ type: 'success', message: '删除成功' })
    } else {
      ElMessage({ type: 'error', message: '删除失败' })
    }
  })
}
const buttons: Button[] = [
  { type: 'primary', text: '查看详情', fun: look },
  { type: 'danger', text: '删除', fun: del },
]

// 获取表单信息
const getInfo = async (params?) => {
  let res
  if (params) {
    res = await GetOperlogInfo(addDateRange({
      pageSize: state.pageSize, pageNum: state.currentPage,
      operName: params.operName,
      roleId: params.roleId,
    }, state.dateRange))
  } else {
    res = await GetOperlogInfo({ pageSize: state.pageSize, pageNum: state.currentPage, ...params })
  }
  if (res.code == 200) {
    state.tableData = res.rows
    state.total = res.total
  }
}
// 查询
const SearchFn = (val) => {
  getInfo(val)
}
const changeTime = (val) => {
  state.dateRange = val
}

const changePage = (val) => {
  state.pageSize = val
  getInfo()
}
const handleSizeChange = (val) => {
  state.currentPage = val
  getInfo()
}


onMounted(async () => {
  const res = await GetRoleList({ tenantRole: '0' })
  if (res.code == 200) {
    state.OptionsMenuId = res.data
  }
  getInfo()
})
</script>