import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import platform from "./platform";
import system from "./system";
import login from "./login";
import localforage from 'localforage';
// import { useStore } from '@/store'
// 1. 配置路由
const routes: RouteRecordRaw[] = [
  ...login, ...platform, ...system
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async (to, _from, next) => {
  const token = await localforage.getItem('szhlsn_token');
  const guideRouter = (path: string, next: any) => {
    if (to.path === '/') {
      next({ path });
    } else {
      next();
    }
  };


  if (token) {
    
    guideRouter('/platform/rolepower', next);
  } else {
    // 无 token
    if (!to.meta.noAuth) {
      if (to.meta.requiresAuth) {
        // 非认证页-场景预览器，判断url参数 再按需跳转
        if (to.query.token) {
          localforage.setItem('szhlsn_token', to.query.token);
          next();
        } else {
          next({ path: '/auth/Login' });
        }
      } else {
        // 非认证页，跳转至登录页
        guideRouter('/auth/Login', next);
      }
    } else {
      // 认证页，正常跳转
      next();
    }
  }
  localforage.setItem('activeIndex', to.path)
});


export default router