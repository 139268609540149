
<template>
  <Search :searchList="state.searchList" @getRuleForm="SearchFn" />
  <el-card class="ContaintCard">
    <Title :TitleName="'菜单管理'">
      <template #btn>
        <div>
          <el-button color="#0D56CC" :icon="Plus" @click="OpenDraw">新增</el-button>
        </div>
      </template>
    </Title>
    <Table :state="state" :isPaginat="false" :defaultheight="'calc(100vh - 21rem)'" :rowkey="'menuId'">
      <template #column>
        <el-table-column prop="menuName" label="菜单名称" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="icon" label="图标">
          <template #default="scope">
            <img v-if="scope.row.icon &&
              scope.row.icon != '#' &&
              scope.row.icon.length > 30
              " style="
              width: calc(36 / 1920 * 100vw);
              height: calc(36 / 1920 * 100vw);
              margin-top: -1px;
            " :src="scope.row.icon" alt="" />
            <SvgIcon v-if="scope.row.icon &&
              scope.row.icon != '#' &&
              scope.row.icon.length < 30
              " class="each-icon text-xl each-svg" :iconClass="scope.row.icon"></SvgIcon>
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="排序" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="perms" label="权限标识" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="component" label="组件路径" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <span :class="scope.row.status == 0 ? 'normal' : 'outage'" class="spanTable">{{
              scope.row.status == 0
              ? '正常'
              : scope.row.status == 1
                ? '停用'
                : '未知状态'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-for="button in buttons" :key="button.text" :type="button.type" link
              @click="button.fun(scope.$index, scope.row)">{{ button.text
              }}</el-button>
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
  <MenuDraw ref="menudraw" :title="state.title" :ruleForm="state.ruleForm" @SetFormData="addForm" />
</template>

<script setup lang="ts">
import Search from './components/Search/MenuSearch.vue'
import Table from '@/components/Table.vue'
import Title from '@/components/Title.vue'
import MenuDraw from './components/Draw/MenuDraw.vue'
import { Plus } from '@element-plus/icons-vue'
import { AddMenuInfo, GetMenuInfo, EditMenuInfo, DelMenuId } from '@/api/menu'
import { handleTree } from '@/api/tree'
import { EpPropMergeType } from 'element-plus/es/utils/vue/props/types'
import { confirmDelete } from '@/utils/messagebox.ts'
interface Button {
  type: EpPropMergeType<
    StringConstructor,
    'primary' | 'danger',
    unknown
  >
  text: string
  fun: Function
}

const menudraw = ref(null)
const state = reactive({
  searchList: {
    'status': '状态',
    'menuName': '菜单名称',
  },
  tableData: [],
  title: '新增菜单',
  ruleForm: {
    parentId: 0,
    menuType: '',
    checkedIcon: '',
    menuName: '',
    icon: '',
    isCache: '',
    orderNum: '',
    path: '',
    component: '',
    visible: '',
    status: '',
    perms: '',
    isFrame: '',
    query: '',
    tenantMenu: '0',
  },
  total: 0
})

const edit = async (_ind, val) => {
  state.title = '编辑菜单'
  menudraw.value.OpenDraw()
  state.ruleForm = { ...val }
}
const del = (_ind, val) => {
  confirmDelete(async () => {
    const res = await DelMenuId(val.menuId)
    if (res.code == 200) {
      getInfo()
      ElMessage({ type: 'success', message: '删除成功' })
    } else {
      ElMessage({ type: 'error', message: '删除失败' })
    }
  })
}
const buttons: Button[] = [
  { type: 'primary', text: '编辑', fun: edit },
  { type: 'danger', text: '删除', fun: del },
]

// 获取表单信息
const getInfo = async (params?) => {
  let res = await GetMenuInfo({ ...params ,tenantMenu:'0'})

  if (res.code == 200) {
    state.tableData = handleTree(res.data, 'menuId')
    state.total = res.total
  }
}

// 查询
const SearchFn = (val) => {
  getInfo(val)
}
// 新增
const OpenDraw = () => {
  state.title = '新增菜单'
  state.ruleForm = {
    parentId: 0,
    menuType: '',
    checkedIcon: '',
    menuName: '',
    icon: '',
    isCache: '',
    orderNum: '',
    path: '',
    component: '',
    visible: '',
    status: '',
    perms: '',
    isFrame: '',
    query: '',
    tenantMenu: '0',
  }
  menudraw.value.OpenDraw()
}

// 新增表单
const addForm = async (val) => {
  console.log(val, '?');

  if (state.title !== '编辑菜单') {
    const res = await AddMenuInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '新增成功' })
    } else {
      ElMessage({ type: 'error', message: '新增失败' })
    }
    getInfo()
  } else {
    const res = await EditMenuInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '修改成功' })
    } else {
      ElMessage({ type: 'error', message: '修改失败' })
    }
    getInfo()
  }
}
onMounted(() => {
  getInfo()
})
</script>

<style lang="scss" scoped>
.spanTable {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: -35%;
    width: 8px;
    height: 8px;
    border-radius: 50px 50px 50px 50px;
  }
}

.normal {
  &::after {
    background: #31a852;
  }
}

.outage {
  &::after {
    background: #ea4132;
  }
}
</style>