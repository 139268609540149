import Auth from '@/layers/Auth/index.vue';
import Login from '@/views/Login.vue'
import NoAuth from '@/views/NoAuth.vue'
const login = [
  {
    path: '/auth',
    component: Auth,
    redirect: '/auth/Login',
    meta: {
      noAuth: true,
    },
    children: [
      {
        path: 'Login',
        component: Login,
      },
      {
        path: 'NoAuth',
        component: NoAuth,
      },
    ]
  }
]
export default login