<template>
  <el-drawer v-model="DrawerVisible" :destroy-on-close="true" class="DrawerStyle">
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <template #default>
      <!-- 内容 -->
      <div class="content">
        <el-form :model="ruleForm" ref="ruleFormRef" :rules="rules">

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="字典名称：" :label-width="formLabelWidth" prop="dictName">
                <el-input v-model="ruleForm.dictName" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="字典类型：" :label-width="formLabelWidth" prop="dictType">
                <el-input v-model="ruleForm.dictType" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="状态：" :label-width="formLabelWidth" prop="status">
                <el-radio-group v-model="ruleForm.status" class="ml-4">
                  <el-radio label="0" size="large">正常</el-radio>
                  <el-radio label="1" size="large">停用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注：" :label-width="formLabelWidth" prop="remark">
                <el-input type="textarea" :maxlength="150" show-word-limit :rows="3" v-model="ruleForm.remark"
                  autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div>
        <el-button color="#417FF9" @click="confirmClick(ruleFormRef)">提交</el-button>
        <el-button class="restBtn" @click="resetForm(ruleFormRef)">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { FormInstance, FormRules } from 'element-plus';
const props = defineProps({
  ruleForm: {
    type: Object, default: {
      dictName: '',
      dictType: '',
      remark: '',
      status: '',
    }
  },
  title: String
})

const formLabelWidth = '100px'
const ruleFormRef = ref<FormInstance>()
const DrawerVisible = ref(false)
const rules = reactive<FormRules>({
  dictName: [
    {
      required: true,
      message: '请填写字典名称',
      trigger: 'blur',
    },
  ],
  dictType: [
    {
      required: true,
      message: '请填写字典类型',
      trigger: 'blur',
    },
  ],
  status: [
    {
      required: true,
      message: '请选择状态',
      trigger: 'change',
    },
  ],
  remark: [
    {
      required: true,
      message: '请填写角色描述备注',
      trigger: 'blur',
    },
  ],
})

const emit = defineEmits(["SetFormData"])

const confirmClick = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {

      emit('SetFormData', props.ruleForm)
      resetForm(formEl)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const OpenDraw = () => {
  DrawerVisible.value = true
  // ruleFormRef.value?.resetFields()
}

const resetForm = (formEl: FormInstance | undefined) => {
  formEl && formEl.resetFields()
  DrawerVisible.value = false
}



onMounted(() => {
})

defineExpose({
  OpenDraw,
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
  color: #000000;
}

.title {
  color: #5087FF;
}
</style>