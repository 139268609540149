
<template>
  <Search :searchList="state.searchList" :OptionsMenuId="state.OptionsMenuId" @getRuleForm="SearchFn"
    @setTime="changeTime" />
  <el-card class="ContaintCard">
    <Title :TitleName="'租户列表'">
      <template #btn>
        <div>
          <el-button color="#0D56CC" :icon="Plus" @click="OpenDraw">新增</el-button>
        </div>
      </template>
    </Title>
    <Table :state="state" @changePage="changePage" @handleSizeChange="handleSizeChange"
      :defaultheight="'calc(100vh - 27rem)'">
      <template #column>
        <el-table-column prop="contact" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号">
        </el-table-column>
        <el-table-column prop="memberType" label="账户类型">
          <template #default="scope">
            {{ scope.row.memberType == 0 ? '个人用户' : '企业用户' }}
          </template>
        </el-table-column>
        <el-table-column prop="roleNames" label="角色名称">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <div @click.stop>
              <el-switch active-value="0" inactive-value="1" v-model="scope.row.status" @change="changeType(scope.row)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template #default="scope">
            <el-button v-for="button in buttons" :key="button.text" :type="button.type" link
              @click="button.fun(scope.$index, scope.row)">{{ button.text
              }}</el-button>
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
  <UserDraw ref="userdraw" :title="state.title" :ruleForm="state.ruleForm" :isreadonly="state.isreadonly"
    @SetFormData="addForm" :OptionsMenuId="state.OptionsMenuId" />
  <PaswordDraw ref="paswordraw" :ruleForm="state.passwordList" @SetFormData="editPawrd" />
  <InfoLook ref="infolook" :InfoData="state.InfoData" />
</template>
  
<script setup lang="ts">
import Search from './components/Search/UserSearch.vue'
import Table from '@/components/Table.vue'
import Title from '@/components/Title.vue'
import UserDraw from './components/Draw/UserDraw.vue'
import PaswordDraw from './components/Draw/PaswordDraw.vue'
import InfoLook from './components/Draw/InfoLook.vue'
import { Plus } from '@element-plus/icons-vue'
import { AddTenantInfo, GetTenantInfo, GetRoleList, EditTenantInfo, EditTenantPaws } from '@/api/tenantlist'
import { EpPropMergeType } from 'element-plus/es/utils/vue/props/types'
import { addDateRange } from '@/utils/dataTime'
interface Button {
  type: EpPropMergeType<
    StringConstructor,
    'primary' | 'danger',
    unknown
  >
  text: string
  fun: Function
}

const userdraw = ref(null)
const paswordraw = ref(null)
const infolook = ref(null)
const state = reactive({
  searchList: {
    'roleId': '账户类型',
    'status': '状态',
    'keyword': '关键词',
    'dateRange': '创建时间'
  },
  tableData: [],
  total: 20, // 总条数
  currentPage: 1, // 当前页
  pageSize: 8, // 分页大小
  title: '新增租户',
  ruleForm: {
    userName: '',
    contact: '',
    phone: '',
    name: '',
    password: '',
    repassword: '',
    email: '',
    roleId: '',
  },
  passwordList: {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
  OptionsMenuId: [],
  isreadonly: false,
  paswordId: null,
  dateRange: [],
  InfoData: []
})
const changeTime = (val) => {
  state.dateRange = val
}
// 重置密码
const openpawrd = async (_ind, row) => {
  state.paswordId = row.id
  state.passwordList = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
    paswordraw.value.OpenDialog()
}
// 确认修改
const editPawrd = async (val) => {
  const res = await EditTenantPaws({
    id: state.paswordId,
    password: val.newPassword
  })
  if (res.code == 200) {
    ElMessage({ type: 'success', message: '修改成功' })
  } else {
    ElMessage({ type: 'error', message: '修改失败' })
  }
}
const edit = async (_ind, val) => {
  state.title = '编辑租户'
  state.ruleForm = { ...val }
  userdraw.value.OpenDraw()
}

const look = async (_ind, val) => {
  state.InfoData = { ...val }
  infolook.value.OpenDraw()
}

const buttons: Button[] = [
  { type: 'primary', text: '编辑', fun: edit },
  { type: 'primary', text: '租户信息', fun: look },
  { type: 'primary', text: '修改密码', fun: openpawrd },
]
// 修改状态
const changeType = (row) => {
  EditTenantInfo({ id: row.id, status: row.status }).then((res) => {
    if (res.code == 200) {
      getInfo()
      ElMessage.success("修改成功");
    } else {
      ElMessage.error("修改失败");
    }
  });
};
// 获取表单信息
const getInfo = async (params?) => {
  let res = await GetTenantInfo(addDateRange(
    {
      pageSize: state.pageSize,
      pageNum: state.currentPage,
      'roleId': params?.roleId,
      'status': params?.status,
      params: {
        keyword: params?.keyword,
      }
    }, state.dateRange))
  if (res.code == 200) {
    state.tableData = res.rows
    state.total = res.total
  }
}

const changePage = (val) => {
  state.pageSize = val
  getInfo()
}
const handleSizeChange = (val) => {
  state.currentPage = val
  getInfo()
}
// 新增
const OpenDraw = () => {
  state.title = '新增租户'
  state.ruleForm = {
    userName: '',
    contact: '',
    phone: '',
    name: '',
    password: '',
    repassword: '',
    email: '',
    roleId: '',
  }
  userdraw.value.OpenDraw()
}

// 新增表单
const addForm = async (_val) => {
  if (state.title !== '编辑租户') {
    const res = await AddTenantInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '新增成功' })
    } else {
      ElMessage({ type: 'error', message: '新增失败' })
    }
    getInfo()
  } else {
    const res = await EditTenantInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '修改成功' })
    } else {
      ElMessage({ type: 'error', message: '修改失败' })
    }
    getInfo()
  }
}
onMounted(async () => {
  const res = await GetRoleList({tenantRole:'1'})
  if (res.code == 200) {
    state.OptionsMenuId = res.data
  }
  getInfo()
})
// 查询
const SearchFn = (val) => {
  getInfo(val)
}
</script>