<template>
  <div class="login-body">
    <div class="login-container">
      <div class="head">
        <div class="name">
          <div class="title">停车管理系统</div>
        </div>
      </div>
      <el-form label-position="top" :rules="state.rules" :model="state.ruleForm" ref="loginForm" class="login-form">
        <el-form-item label="账号" prop="username">
          <el-input type="text" v-model.trim="state.ruleForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model.trim="state.ruleForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="color: #333">登录表示您已同意<a>《服务条款》</a></div>
          <el-button style="width: 100%" type="primary" @click="submitForm">立即登录</el-button>
          <el-checkbox v-model="state.checked" @change="!state.checked">下次自动登录</el-checkbox>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref ,computed} from 'vue'
import { Login, info } from '@/api/login'
import { getRouterURL} from '@/api/getRouters'
import { localSet } from '@/utils'
import router from '@/router'
import { ElMessage } from 'element-plus';
import { useUserStore } from '@/store'
const userStore = useUserStore()
const loginForm = ref(null)
const state = reactive({
  ruleForm: {
    username: '',
    password: ''
  },
  checked: true,
  rules: {
    username: [
      { required: 'true', message: '账户不能为空', trigger: 'blur' }
    ],
    password: [
      { required: 'true', message: '密码不能为空', trigger: 'blur' }
    ]
  }
})
const submitForm = () => {
  loginForm.value.validate(async (valid) => {
    if (valid) {
      let res = await Login(state.ruleForm)
      if (res.code == 200) {
        await userStore.setToken(res.data);
        getRouterURL().then((row) => {
          if (row.data.length > 0) {
            router.push({ path: '/platform/rolepower' });
          } else {
            // ElMessage.error('该用户暂无权限，请联系管理员。');
          }
        })
      }
    } else {
      return false;
    }
  })
}
const resetForm = () => {
  loginForm.value.resetFields();
}
</script>

<style scoped>
.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
}

.login-container {
  width: 420px;
  height: 500px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 21px 41px 0px rgba(0, 0, 0, 0.2);
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 20px 0;
}

.head img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.head .title {
  font-size: 28px;
  color: #1BAEAE;
  font-weight: bold;
}

.head .tips {
  font-size: 12px;
  color: #999;
}

.login-form {
  width: 70%;
  margin: 0 auto;
}

.login-form>>>.el-form--label-top .el-form-item__label {
  padding: 0;
}

.login-form>>>.el-form-item {
  margin-bottom: 0;
}
</style>