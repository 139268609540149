<script setup lang="ts">
import { localGet } from '@/utils'
import { useRouter, useRoute } from 'vue-router'
import { User, ArrowDown, ArrowRight } from '@element-plus/icons-vue'
import { getRouterURL, getUser } from '@/api/getRouters'
import { useUserStore } from '@/store'
import localforage from 'localforage';
const noMenu = ['/login']
const router = useRouter()
const route = useRoute()
const state = reactive({
  showMenu: false,
  currentPath: '',
  permissionsUrl: [],
  userInfo: {} as any
})
router.afterEach((to, _from) => {
  state.showMenu = noMenu.includes(to.path)
})
const userStore = useUserStore()
const breadcrumbData = ref([])
const menuList = ref([
])
const permissionsUrl = ref([])
const getBreadcrumbData = () => {
  breadcrumbData.value = route.matched.filter((item) => {
    return item.meta && item.meta.routeName;
  });
};
const getActive = () => {
  localGet('activeIndex', (value: any) => {
    state.currentPath = value
  })
}


watch(
  route,
  () => {
    getActive()
    getBreadcrumbData();
  },
  {
    immediate: true,
  }
);

const getUserInfo = async () => {
  let res = await getUser()
  if (res.code == 200) {
    userStore.setBaseInfo(res.data.user)
  }
  state.userInfo = userStore._baseInfo
}

const getRouter = async () => {
  const res = await getRouterURL()
  if (res.data && res.data.length > 0) {
    userStore.setRoutesList(res.data)
    var arr = []
    console.log(userStore._routesList, 'userStore._routesList');
    userStore._routesList.forEach((item: any) => {
      if (!item.hidden) {
        var obj: any = {};
        obj.name = item.meta.title;
        obj.path = item.component;
        obj.routerUrl = item.name;
        obj.img = item.meta.icon;
        obj.checkedIcon = item.meta.checkedIcon;
        obj.children = [];
        arr.push(obj);
        if (item.children) {
          item.children.map((res1) => {
            if (!res1.hidden) {
              var obj1: any = {};
              obj1.name = res1.meta.title;
              obj1.path = res1.component;
              obj1.svg = res1.meta.icon;
              obj.routerUrl = res1.name;
              obj1.children = [];
              obj.children.push(obj1);
              permissionsUrl.value.push(res1.component);
              if (res1.children) {
                res1.children.map((row) => {
                  if (!row.hidden) {
                    var obj2: any = {};
                    obj2.name = row.meta.title;
                    obj2.path = row.component;
                    obj.routerUrl = row.name;
                    permissionsUrl.value.push(row.component);
                    obj1.children.push(obj2);
                  }
                });
              }
            }
          });
        }
      }
    })
    permissionsUrl.value.push('dictory')
    localforage.setItem(
      'permissionsUrl',
      JSON.stringify(permissionsUrl.value)
    )
    menuList.value = arr
    console.log(permissionsUrl.value, 'menuList.value');

  } else {
    menuList.value = []
  }
}

const loginout = () => {
  localforage.removeItem('permissionsUrl')
  localforage.removeItem('szhlsn_token')
  router.push({ path: '/auth/Login' })
}

onMounted(async () => {
  getRouter()
  getUserInfo()
  getActive()
  const permissionsUrl = await localforage.getItem('permissionsUrl') as any;
  if (permissionsUrl) {
    const isPermissions =
      JSON.parse(permissionsUrl).indexOf(route.path) !== -1
        ? false
        : JSON.parse(permissionsUrl).indexOf(route.path.split('/')[2]) != -1 ? false : true;

    if (isPermissions) {
      router.push({ path: '/auth/NoAuth' });
    }
  }
})
</script>

<template>
  <div class="layout">
    <el-container style="flex-direction: column;">
      <router-view v-slot="{ Component, route }">
      <el-header>
        <p>平台系统管理</p>
        <el-dropdown :hide-on-click="false">
          <span class="el-dropdown-link">
            <el-icon>
              <User />
            </el-icon>
            {{ state.userInfo.nickName }}
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-header>
      <el-container style="flex-direction: row;">

        <el-aside width="200px">
          <el-menu :router="true" unique-opened class="el-menu-vertical-demo" background-color="#1C2128"
            active-text-color="#FFF" text-color="#FFF" :default-active="state.currentPath">
            <el-sub-menu :index="item.path" v-for="(item, ind) in menuList" :key="ind">
              <template #title>
                <img class="icon" :src="item.img">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="_item.path" v-for="(_item, _ind) in item.children" :key="_ind">{{
                  _item.name
                }}</el-menu-item>
              </el-menu-item-group>
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-main>
          <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item :to="{ path: '/' }">平台系统管理</el-breadcrumb-item>
            <el-breadcrumb-item v-for="item in breadcrumbData" :key="item.path">{{
              item.meta.routeName }}
            </el-breadcrumb-item>
          </el-breadcrumb>
          <component :is="Component" :key="route.path" />
        </el-main>
      </el-container>
    </router-view>
    </el-container>
    <!-- <el-container v-else>
      <router-view />
    </el-container> -->
  </div>
</template>

<style lang="scss" >
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.layout {
  background: #F0F2F5;

  .el-header {
    height: 56px;
    background: #212A37;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: #FFF;

    p {
      font-size: 24px;
    }

    .el-dropdown-link {
      color: #FFF;
      font-size: 16px;

      &:focus-visible {
        outline: none !important;
      }

      .el-icon {
        vertical-align: bottom;
      }
    }
  }

  .el-container {
    height: 100vh;
  }

  .el-aside {
    padding-top: 24px;
    background: #1C2128;
  }

  .el-menu {
    border-right: none !important;

    .el-sub-menu {
      .el-sub-menu__title {
        padding: 0 16px;
        font-size: 16px;
      }

      .el-menu-item-group__title {
        display: none;
      }
    }

    .el-menu-item.is-active {
      background: #0D56CC !important;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.el-main {
  padding: 24px;

  .el-breadcrumb {
    margin-bottom: 16px;
    font-size: 16px;
  }
}
</style>
