import MenuMament from '@/views/system/MenuMament.vue'
import DictMament from '@/views/system/DictMament.vue'
import Dictory from '@/views/system/Dictory.vue'
import SysAgment from '@/views/system/SysAgment.vue'
import OperatLog from '@/views/system/OperatLog.vue'
import Content from '@/layers/Content/index.vue';
export default [
  {
    path: '/system',
    name: '系统设置',
    redirect: '/system/menumament',
    component:Content,
    children: [
      {
        path: 'menumament',
        name: 'menumament',
        component:  MenuMament,
        meta: {
          requiresAuth: true,
          routeName:'菜单管理'
        },
      },
      {
        path: 'dictmament',
        name: 'dictmament',
        component:  DictMament,
        meta: {
          requiresAuth: true,
          routeName:'字典管理'
        },
      },
      {
        path: 'dictory/:dictType',
        name: 'dictory',
        component:  Dictory,
        meta: {
          requiresAuth: true,
          routeName:'字典信息'
        },
      },
      {
        path: 'operatlog',
        name: 'operatlog',
        component:  OperatLog,
        meta: {
          requiresAuth: true,
          routeName:'操作日志'
        },
      },
      {
        path: 'sysagment',
        name: 'sysagment',
        component:  SysAgment,
        meta: {
          requiresAuth: true,
          routeName:'系统参数'
        },
      },
    ]
  }
]