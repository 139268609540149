<template>
  <el-drawer v-model="DrawerVisible" :destroy-on-close="true" class="DrawerStyle">
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <template #default>
      <!-- 内容 -->
      <div class="content">
        <el-form :model="ruleForm" ref="ruleFormRef" :rules="rules">

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="登录账号：" :label-width="formLabelWidth" prop="userName">
                <el-input v-model="ruleForm.userName" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="联系人：" :label-width="formLabelWidth" prop="contact">
                <el-input v-model="ruleForm.contact" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="公司名称：" :label-width="formLabelWidth" prop="name">
                <el-input v-model="ruleForm.name" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="title == '新增租户'">
            <el-col :span="24">
              <el-form-item label="登录密码：" :label-width="formLabelWidth" prop="password">
                <el-input v-model="ruleForm.password" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="title == '新增租户'">
            <el-col :span="24">
              <el-form-item label="确认密码：" :label-width="formLabelWidth" prop="repassword">
                <el-input v-model="ruleForm.repassword" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="手机号：" :label-width="formLabelWidth" prop="phone">
                <el-input v-model="ruleForm.phone" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="邮箱：" :label-width="formLabelWidth" prop="email">
                <el-input v-model="ruleForm.email" autocomplete="off" :readonly="isreadonly" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="角色类型：" :label-width="formLabelWidth" prop="roleId">
                <el-select clearable v-model="ruleForm.roleId" placeholder="选择类型">
                  <el-option v-for="item in OptionsMenuId" :key="item.roleId" :label="item.roleName"
                    :value="item.roleId + ''" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div>
        <el-button color="#417FF9" @click="confirmClick(ruleFormRef)">提交</el-button>
        <el-button class="restBtn" @click="resetForm(ruleFormRef)">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { FormInstance, FormRules } from 'element-plus';

interface OptionMenuId {
  roleId: string
  roleName: string
}

const props = defineProps({
  ruleForm: {
    type: Object, default: {
      userName: '',
      contact: '',
      phone: '',
      name: '',
      password: '',
      repassword: '',
      email: '',
      roleId: '',
    }
  },
  isreadonly: Boolean,
  title: String,
  OptionsMenuId: Array as () => Array<OptionMenuId>
})

const validatePass = (_rule, value, callback) => {
  let reg =
    '^(?=.*\\d)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{8,20}';
  if (!new RegExp(reg).test(value)) {
    callback(new Error('密码长度为8-20个字符,须含字母、数字、符号至少2种'));
  } else callback();
}

const validatePass2 = (_rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'));
  } else if (value !== props.ruleForm.password) {
    callback(new Error('两次输入密码不一致!'));
  } else callback();
};

const formLabelWidth = '100px'
const ruleFormRef = ref<FormInstance>()
const DrawerVisible = ref(false)
const rules = reactive<FormRules>({
  userName: [
    {
      required: true,
      message: '请填写登录账号',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '请填写登录密码',
      trigger: 'blur',
    },
    {
      required: true,
      validator: validatePass,
      trigger: 'blur',
    },
  ],
  contact: [
    {
      required: true,
      message: '请填写联系人',
      trigger: 'blur',
    },
  ],
  name: [
    {
      required: true,
      message: '请填写公司名称',
      trigger: 'blur',
    },
  ],
  repassword: [
    {
      required: true,
      message: '请填写登录密码',
      trigger: 'blur',
    },
    { required: true, validator: validatePass2, trigger: 'blur' },
  ],
  phone: [
    {
      required: true,
      message: '请填写手机号',
      trigger: 'blur',
    },
  ],
  roleId: [
    {
      required: true,
      message: '请选择角色类型',
      trigger: 'change',
    }
  ],
})

const emit = defineEmits(["SetFormData"])

const confirmClick = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {

      emit('SetFormData', props.ruleForm)
      resetForm(formEl)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const OpenDraw = () => {
  DrawerVisible.value = true
  // ruleFormRef.value?.resetFields()
}

const resetForm = (formEl: FormInstance | undefined) => {
  formEl && formEl.resetFields()
  DrawerVisible.value = false
}


defineExpose({
  OpenDraw,
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
  color: #000000;
}

.title {
  color: #5087FF;
}
</style>