import { request } from '@/request/http';


export const Login = (params) =>
  request({
    method: 'POST',
    url: `/auth/login?password=${params.password}&username=${params.username}`,
  })


export const info = () => request({
  url: '/getInfo',
  method: 'GET',
})

