import { request } from '@/request/http';


export const AddDictInfo = (params) => request({
  url: '/system/dict/type',
  method: 'POST',
  params
})

export const GetDictInfo = (params) => request({
  url: '/system/dict/type/list',
  method: 'GET',
  params
})

export const clearCache = () => request({
  url: '/system/dict/type/clearCache',
  method: 'DELETE',
})

export const DelDictId = (params) => request({
  url: `/system/dict/type`,
  method: 'DELETE',
  params
})


export const EditDictInfo = (params) => request({
  url: '/system/dict/type',
  method: 'PUT',
  params
})
