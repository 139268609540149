<template>
  <el-drawer v-model="dialogVisble" :before-close="handleClose" size="30.3%" class="DrawerStyle loginLogDiolog">
    <template #header>
      <h4>日志详情</h4>
    </template>
    <div class="cardPart">
      <div class="partOne">
        <span>操作员：</span>
        <span style="color: rgba(0, 0, 0, 0.8)">{{
          LoginLogdata.operName
        }}</span>
      </div>
      <el-divider class="line" />
      <div class="partOne">
        <span>角色名称：</span>
        <span style="color: rgba(0, 0, 0, 0.8)">{{
          LoginLogdata.roleNames
        }}</span>
      </div>
      <el-divider class="line" />
      <div class="partOne">
        <span>路径：</span>
        <span style="color: rgba(0, 0, 0, 0.8)">{{
          LoginLogdata.operUrl
        }}</span>
      </div>
      <el-divider class="line" />
      <div class="partOne">
        <span>操作时间：</span>
        <span style="color: rgba(0, 0, 0, 0.8)">{{
          LoginLogdata.operTime
        }}</span>
      </div>
      <el-divider class="line" />
      <div class="partOne">
        <span>IP地址：</span>
        <span style="color: rgba(0, 0, 0, 0.8)">{{ LoginLogdata.operIp }}</span>
      </div>
      <el-divider class="line" />
      <div class="partOne">
        <span>操作记录：</span>
        <span style="color: rgba(0, 0, 0, 0.8)">{{
          LoginLogdata.jsonResult
        }}</span>
      </div>
    </div>
  </el-drawer>
</template>
<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  LoginLogdata: {
    type: Object, default: {
      operName: '',
      roleNames: '',
      operUrl: '',
      jsonResult: '',
      operIp: '',
    }
  }
})
const dialogVisble = ref(false)
const handleOpen = () => {
  dialogVisble.value = true
}
const handleClose = () => {
  dialogVisble.value = false
}
defineExpose({
  handleOpen,
})
</script>
<style lang="scss" scoped>
:deep(.el-drawer) {
  width: 870px !important;
  background: #1e2233;
  border-radius: 0px 0px 0px 0px;
}

.cardPart {
  width: calc(548 / 1920 * 100vw);
  background: #fcfdff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: calc(1 / 1920 * 100vw) solid #cacfdc;
  margin-top: calc(16 / 1920 * 100vw);

  .partOne {
    padding: 19px 32px 17px 32px;
    font-size: 14px;
    color: #6b7180;
  }

  .line {
    width: calc(458 / 1920 * 100vw);
    margin: 0;
    margin-left: calc(32 / 1920 * 100vw);
    opacity: 1;
    border-color: #ebeef5;
    padding: 0;
  }
}
</style>
<style lang="scss">
.loginLogDiolog {
  .el-drawer__body {
    padding: 0 16px !important;
    overflow: hidden;
  }
}
</style>
