function confirmDelete(ok: Function) {
  ElMessageBox.confirm(
    '将要删除本条记录，是否继续?',
    '警告',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    ok();
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消操作'
    });
  })
}

export { confirmDelete }
