import { request } from '@/request/http';


export const AddRoleInfo = (params) => request({
    url: '/system/role',
    method: 'POST',
    params
  })
  
export const GetRoleInfo = (params) => request({
    url: '/system/role/list',
    method: 'GET',
    params
  })
  
  
export const GetRoleId = (params) => request({
    url: `/system/role/getRole/${params}`,
    method: 'GET',
  })

  
export const DelRoleId = (params) => request({
    url: `/system/role`,
    method: 'DELETE',
    params
  })
  

  export const EditRoleInfo = (params) => request({
    url: '/system/role',
    method: 'PUT',
    params
  })
  