<template>
  <div id="app">
    <router-view v-slot="{ Component, route }">
      <transition name="slide-fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import axios from "axios"
import Path from '@/utils/Path';
import localforage from 'localforage';
import ConfigServer from '@/config/Server';

const setServerInfo = () => {
  axios({
    method: 'GET',
    url: `${window.location.origin}${Path.getBase()}config.json`
  }).then((res) => {
    const env = import.meta.env.MODE;
    const configServer = new ConfigServer(res.data);
    const data = configServer.getConfig(env);
    localforage.setItem('env', env);
    localforage.setItem('protocol', data.protocol);
    localforage.setItem('IP', data.ip);
    localforage.setItem('basePath', data.basePath);
  })
}
onMounted(() => {
  setServerInfo()
})

</script>

<style lang="scss"></style>
