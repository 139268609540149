<template>
  <el-drawer v-model="DrawerVisible" :destroy-on-close="true" class="DrawerStyle">
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <template #default>
      <!-- 内容 -->
      <div class="content">
        <el-form :model="ruleForm" ref="ruleFormRef" :rules="rules">

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="上级菜单:" prop="parentId" :label-width="formLabelWidth">
                <treeselect v-model="ruleForm.parentId" :options="state.menuOptions" :normalizer="normalizer"
                  :show-count="true" placeholder="选择上级菜单" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="菜单类型" prop="menuType" :label-width="formLabelWidth">
                <el-radio-group v-model="ruleForm.menuType" class="platform-input size-large-2">
                  <el-radio label="M">目录</el-radio>
                  <el-radio label="C">菜单</el-radio>
                  <el-radio label="F">按钮</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" v-if="ruleForm.menuType != 'F'">
              <el-form-item label="菜单图标" :label-width="formLabelWidth">
                <span v-if="ruleForm.icon">
                  <span v-if="ruleForm.icon.substring(0, 10) == 'data:image'">
                    <el-upload class="upload-demo" :on-change="handleRemove" multiple :limit="3">
                      <img v-if="ruleForm.icon && ruleForm.icon != '#'"
                        style="width: 36px; height: 36px; margin-top: -1px" :src="ruleForm.icon" alt="" />
                      <el-button v-else size="small">点击上传</el-button>
                    </el-upload>
                  </span>
                  <el-input v-else class="platform-input" v-model="ruleForm.icon" placeholder="请输入"></el-input>
                </span>
                <span v-else style="display: flex">
                  <el-upload class="upload-demo" :on-change="handleRemove" multiple :limit="3">
                    <img v-if="ruleForm.icon && ruleForm.icon != '#'" style="width: 36px; height: 36px; margin-top: -1px"
                      :src="ruleForm.icon" alt="" />
                    <el-button v-else size="small" type="text">点击上传</el-button>
                  </el-upload>
                  <el-input class="platform-input" v-model="ruleForm.icon" placeholder="请输入icon名称"></el-input>
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="选中菜单" :label-width="formLabelWidth">
                <el-upload class="upload-demo" :on-change="handleRemove1" multiple :limit="3">
                  <img v-if="ruleForm.checkedIcon && ruleForm.checkedIcon != '#'"
                    style="width: 36px; height: 36px; margin-top: -1px" :src="ruleForm.checkedIcon" alt="" />
                  <el-button v-else size="small">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="菜单名称" prop="menuName" :label-width="formLabelWidth">
                <el-input class="platform-input size-large-4" v-model="ruleForm.menuName"
                  placeholder="请输入菜单名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="显示排序" prop="orderNum" :label-width="formLabelWidth">
                <el-input-number class="platform-input size-large-5" v-model="ruleForm.orderNum" controls-position="right"
                  :min="0"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="路由地址" prop="path" v-if="ruleForm.menuType != 'F'" :label-width="formLabelWidth">
                <el-input class="platform-input" v-model="ruleForm.path" placeholder="请输入路由地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" v-if="ruleForm.menuType != 'F'" >
              <el-form-item label="组件地址" prop="component" :label-width="formLabelWidth">
                <el-input class="platform-input" v-model="ruleForm.component" placeholder="请输入组件地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" v-if="ruleForm.menuType != 'M'">
              <el-form-item label="权限字符" :label-width="formLabelWidth">
                <el-input v-model="ruleForm.perms" placeholder="请输入权限标识" maxlength="100" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" v-if="ruleForm.menuType == 'C'" >
              <el-form-item label="路由参数" :label-width="formLabelWidth">
                <el-input v-model="ruleForm.query" placeholder="请输入路由参数" maxlength="255" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" v-if="ruleForm.menuType != 'F'">
              <el-form-item label="是否外链" prop="isFrame" :label-width="formLabelWidth">
                <el-radio-group class="platform-input" v-model="ruleForm.isFrame">
                  <el-radio label="1">否</el-radio>
                  <el-radio label="0">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="是否缓存" prop="isCache" :label-width="formLabelWidth">
                <el-radio-group v-model="ruleForm.isCache" class="platform-input size-large-2">
                  <el-radio label="0">缓存</el-radio>
                  <el-radio label="1">不缓存</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="显示状态" prop="visible" :label-width="formLabelWidth">
                <el-radio-group class="platform-input" v-model="ruleForm.visible">
                  <el-radio label="0">显示</el-radio>
                  <el-radio label="1">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="菜单状态" prop="status" :label-width="formLabelWidth">
                <el-radio-group class="platform-input" v-model="ruleForm.status">
                  <el-radio label="0">正常</el-radio>
                  <el-radio label="1">停用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="租户独有" class="mr-6" prop="tenantMenu" :label-width="formLabelWidth">
                <el-radio-group class="platform-input" v-model="ruleForm.tenantMenu">
                  <el-radio label="0">否</el-radio>
                  <el-radio label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-form>
      </div>
    </template>
    <template #footer>
      <div>
        <el-button color="#417FF9" @click="confirmClick(ruleFormRef)">提交</el-button>
        <el-button class="restBtn" @click="resetForm(ruleFormRef)">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { FormInstance, FormRules } from 'element-plus';
// import the component
import Treeselect from 'vue3-treeselect';
// import the styles
import 'vue3-treeselect/dist/vue3-treeselect.css';
import { GetTenantMenuTree } from '@/api/menu'
const props = defineProps({
  ruleForm: {
    type: Object, default: {
      parentId: [],
      menuType: '',
      checkedIcon: '',
      menuName: '',
      icon: '',
      isCache: '',
      orderNum: '',
      path: '',
      component: '',
      visible: '',
      status: '',
      perms: '',
      isFrame: '',
      query: '',
      tenantMenu: '0',
    }
  },
  title: String
})
const formLabelWidth = '80px'
const ruleFormRef = ref<FormInstance>()
const DrawerVisible = ref(false)
const rules = reactive<FormRules>({
  num: [{ required: true, message: '请选择菜单类型', trigger: 'change' }],
  resource1: [
    { required: true, message: '请选择菜单类型', trigger: 'change' },
  ],
  name1: [
    { required: true, message: '请输入菜单名称', trigger: 'blur' },
    { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' },
  ],
  component: [
    { required: true, message: '请输入组件地址', trigger: 'blur' },
  ],
  resource2: [
    { required: true, message: '请选择是否缓存', trigger: 'change' },
  ],
  tenant_menu: [
    { required: true, message: '请选择是否租户独有', trigger: 'change' },
  ],
  name2: [
    { required: true, message: '请输入路由地址', trigger: 'blur' },
    {
      min: 2,
      max: 16,
      message: '长度在 2 到 16 个字符',
      trigger: 'blur',
    },
  ],
  resource3: [
    { required: true, message: '请选择是否外链', trigger: 'change' },
  ],
  resource4: [
    { required: true, message: '请选择显示状态', trigger: 'change' },
  ],
  resource5: [
    { required: true, message: '请选择菜单状态', trigger: 'change' },
  ],
})

const emit = defineEmits(["SetFormData"])

const confirmClick = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {

      emit('SetFormData', props.ruleForm)
      resetForm(formEl)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const state = reactive({
  menuOptions: []
})

const OpenDraw = () => {
  DrawerVisible.value = true
  // ruleFormRef.value?.resetFields()
}

const resetForm = (formEl: FormInstance | undefined) => {
  formEl && formEl.resetFields()
  DrawerVisible.value = false
}
const handleRemove = (file, _fileList) => {
  var reader = new FileReader();
  reader.readAsDataURL(file.raw);
  reader.onload = () => {
    props.ruleForm.icon = reader.result;
  };
  reader.onerror = function(_error) { };
}

const handleRemove1 = (file, _fileList) => {
  var reader = new FileReader();
  reader.readAsDataURL(file.raw);
  reader.onload = () => {
    props.ruleForm.checkedIcon = reader.result;
  };
  reader.onerror = function(_error) { };
}
/** 转换菜单数据结构 */

const normalizer = (node) => {
  if (node.children && !node.children.length) {
    delete node.children;
  }
  return {
    id: node.id,
    label: node.label,
    children: node.children,
  };
}
const getMenuOpitons = async() =>{
  let res = await GetTenantMenuTree()
  if (res.code == 200) {
    state.menuOptions = []
    const menu = { id: 0, label: '主类目', children: [] };
    menu.children = res.data;
    state.menuOptions.push(menu);
  }
}

onMounted(() => {
  getMenuOpitons()
})

defineExpose({
  OpenDraw,
  getMenuOpitons
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
  color: #000000;
}

.title {
  color: #5087FF;
}
</style>