import { request } from '@/request/http';


export const AddUserInfo = (params) => request({
    url: '/system/user',
    method: 'POST',
    params
  })
  
export const GetUserInfo = (params) => request({
    url: '/system/user/list',
    method: 'GET',
    params
  })

export const GetUserID = (params) => request({
    url: `/system/user/${params}`,
    method: 'GET',
  })
  
  
export const GetRoleList= (params) => request({
    url: `/system/role/getRoleList`,
    method: 'GET',
    params
  })

  
export const DelUserId = (params) => request({
    url: `/system/user`,
    method: 'DELETE',
    params
  })
  

  export const EditUserInfo = (params) => request({
    url: '/system/user',
    method: 'PUT',
    params
  })
  

  export const EditUserPaws = (params) => request({
    url: '/system/user/resetPwd',
    method: 'PUT',
    params
  })
  