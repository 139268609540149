import { request } from '@/request/http';


export const AddTenantInfo = (params) => request({
    url: '/system/tenant',
    method: 'POST',
    params
  })
  
export const GetTenantInfo = (params) => request({
    url: '/system/tenant/list',
    method: 'GET',
    params
  })

export const GetTenantID = (params) => request({
    url: `/system/tenant/${params}`,
    method: 'GET',
  })
  
  
export const GetRoleList= (params) => request({
    url: `/system/role/getRoleList`,
    method: 'GET',
    params
  })

  
export const DelTenantId = (params) => request({
    url: `/system/tenant`,
    method: 'DELETE',
    params
  })
  

  export const EditTenantInfo = (params) => request({
    url: '/system/tenant',
    method: 'PUT',
    params
  })
  

  export const EditTenantPaws = (params) => request({
    url: '/system/tenant/resetPwd',
    method: 'PUT',
    params
  })
  