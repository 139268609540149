<script setup lang="ts">
import {ArrowRightBold,ArrowLeftBold} from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
 defineProps({
  state: Object,
  defaultheight:{
    type:String,default:'calc(100vh - 21rem)'
  },
  treeProps:{
    type:Object,default:{ children: 'children', hasChildren: 'hasChildren' }
  },
  rowkey:{
    type:String,default:'id'
  },
  isPaginat:{
    type:Boolean,default:true
  }
})

const emit = defineEmits(["handleSelectionChange","changePage","handleSizeChange"])

const handleSelectionChange = (val: any) => {
  emit('handleSelectionChange',val)
}

const handleSizeChange = (val: any) =>{
  emit("handleSizeChange",val)
}

const changePage = (val: any) => {
  emit("changePage",val)
}
defineExpose({})

</script>


<template>
  <el-table  :header-cell-style="{ 'text-align': 'center', 'color': '#2E3F5A' }" :cell-style="{ 'text-align': 'center' }"
    :load="state.loading" :data="state.tableData" tooltip-effect="dark" style="width: 100%"
    @selection-change="handleSelectionChange" :row-key="rowkey" :stripe="true" :height="defaultheight" :tree-props="treeProps" :indent="32">
    <slot name='column'></slot>
  </el-table>
  <el-config-provider :locale="zhCn">
  <el-pagination v-if="isPaginat" small class="paginationCenter" layout="total,prev, pager, next" :total="state.total"
    :page-size="state.pageSize" :current-page="state.currentPage" @current-change="handleSizeChange" :prev-icon="ArrowLeftBold" :next-icon="ArrowRightBold" 
    @size-change="changePage"
    />
  </el-config-provider>
</template>


<style lang="scss" >
.el-table__body-wrapper tr {
  height: 80px;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #FCFDFF;
}

.el-table__header-wrapper tr {
  background: #F4F6FF;
  height: 69px;
}

.el-table th.el-table__cell {
  background: transparent;
}
</style>

<style lang="scss">
.el-pager li.is-active {
  background: #E8F4FF;
  color: #1664FF;
}
</style>