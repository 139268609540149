
<template>
  <Search :searchList="state.searchList" @getRuleForm="SearchFn" />
  <el-card class="ContaintCard">
    <Title :TitleName="'系统参数'">
      <template #btn>
        <div>
          <el-button color="#0D56CC" style="--el-button-bg-color:#fff" plain :icon="Files" @click="clearCacheFun"
            >清空缓存</el-button
          >
          <el-button color="#0D56CC" :icon="Plus" @click="OpenDraw"
            >新增</el-button
          >
        </div>
      </template>
    </Title>
    <Table
      :state="state"
      @changePage="changePage"
      @handleSizeChange="handleSizeChange"
      :defaultheight="'calc(100vh - 27rem)'"
    >
      <template #column>
        <el-table-column prop="configId" label="参数ID"></el-table-column>
        <el-table-column
          prop="configName"
          label="参数名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="configKey" label="参数Key" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="configValue"
          label="参数Value"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="configType" label="系统内置">
          <template #default="scope">
            {{ scope.row.configType == "Y" ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              v-for="button in buttons"
              :key="button.text"
              :type="button.type"
              link
              @click="button.fun(scope.$index, scope.row)"
              >{{ button.text }}</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
  <SysagDraw
    ref="sysagdraw"
    :title="state.title"
    :ruleForm="state.ruleForm"
    @SetFormData="addForm"
  />
</template>

<script setup lang="ts">
import Search from "./components/Search/SysSearch.vue";
import Table from "@/components/Table.vue";
import Title from "@/components/Title.vue";
import SysagDraw from "./components/Draw/SysagDraw.vue";
import { Plus, Files } from "@element-plus/icons-vue";
import {
  AddSysaInfo,
  GetSysaInfo,
  EditSysaInfo,
  DelSysaId,
  clearCache,
} from "@/api/sysag";
import { EpPropMergeType } from "element-plus/es/utils/vue/props/types";
import { confirmDelete } from "@/utils/messagebox.ts";
interface Button {
  type: EpPropMergeType<StringConstructor, "primary" | "danger", unknown>;
  text: string;
  fun: Function;
}

const sysagdraw = ref(null);
const state = reactive({
  searchList: {
    configType: "系统内置",
    configName: "参数名称",
    configKey: "参数key",
  },
  tableData: [],
  total: 20, // 总条数
  currentPage: 1, // 当前页
  pageSize: 8, // 分页大小
  title: "新增系统参数",
  ruleForm: {
    configName: "",
    configKey: "",
    configValue: "",
    configType: "",
    remark: "",
  },
});

const edit = async (_ind, val) => {
  state.title = "编辑系统参数";
  sysagdraw.value.OpenDraw();
  state.ruleForm = { ...val };
};
const del = (_ind, val) => {
  confirmDelete(async () => {
    const res = await DelSysaId(val.configId);
    if (res.code == 200) {
      getInfo();
      ElMessage({ type: "success", message: "删除成功" });
    } else {
      ElMessage({ type: "error", message: "删除失败" });
    }
  });
};
const buttons: Button[] = [
  { type: "primary", text: "编辑", fun: edit },
  { type: "danger", text: "删除", fun: del },
];
const clearCacheFun = () => {
  clearCache().then((res: any) => {
    if(res.code == 200){
    ElMessage.success("清空成功");
   }
  });
};
// 获取表单信息
const getInfo = async (params?) => {
  let res = await GetSysaInfo({
    pageSize: state.pageSize,
    pageNum: state.currentPage,
    ...params,
  });
  if (res.code == 200) {
    state.tableData = res.rows;
    state.total = res.total;
  }
};

const changePage = (val) => {
  state.pageSize = val;
  getInfo();
};
const handleSizeChange = (val) => {
  state.currentPage = val;
  getInfo();
};
// 查询
const SearchFn = (val) => {
  getInfo(val);
};
// 新增
const OpenDraw = () => {
  state.title = "新增系统参数";
  state.ruleForm = {
    configName: "",
    configKey: "",
    configValue: "",
    configType: "",
    remark: "",
  };
  sysagdraw.value.OpenDraw();
};

// 新增表单
const addForm = async (_val) => {
  if (state.title !== "编辑系统参数") {
    const res = await AddSysaInfo(state.ruleForm);
    if (res.code == 200) {
      ElMessage({ type: "success", message: "新增成功" });
    } else {
      ElMessage({ type: "error", message: "新增失败" });
    }
    getInfo();
  } else {
    const res = await EditSysaInfo(state.ruleForm);
    if (res.code == 200) {
      ElMessage({ type: "success", message: "修改成功" });
    } else {
      ElMessage({ type: "error", message: "修改失败" });
    }
    getInfo();
  }
};
onMounted(() => {
  getInfo();
});
</script>