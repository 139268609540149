import { request } from '@/request/http';


export const treeselect = () => request({
    url: '/system/menu/treeselect?status=0&tenantMenu=0',
    method: 'GET',
  })
  
  
  export const treeTenantselect = () => request({
    url: '/system/menu/treeselect?status=0&tenantMenu=1',
    method: 'GET',
  })
  