
<template>
  <Search :searchList="state.searchList" :OptionsMenuId="state.OptionsMenuId" @getRuleForm="SearchFn" />
  <el-card class="ContaintCard">
    <Title :TitleName="'用户列表'">
      <template #btn>
        <div>
          <el-button color="#0D56CC" :icon="Plus" @click="OpenDraw">新增</el-button>
        </div>
      </template>
    </Title>
    <Table :state="state" @changePage="changePage" @handleSizeChange="handleSizeChange"
      :defaultheight="'calc(100vh - 27rem)'">
      <template #column>
        <el-table-column prop="userId" label="ID"></el-table-column>
        <el-table-column prop="userName" label="登录账号">
        </el-table-column>
        <el-table-column prop="nickName" label="用户名称">
        </el-table-column>
        <el-table-column prop="roleNames" label="用户类型">
        </el-table-column>
        <el-table-column prop="phonenumber" label="手机号">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-for="button in buttons" :key="button.text" :type="button.type" link
              @click="button.fun(scope.$index, scope.row)">{{ button.text
              }}</el-button>
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
  <UserDraw ref="userdraw" :title="state.title" :ruleForm="state.ruleForm" :isreadonly="state.isreadonly"
    @SetFormData="addForm" :OptionsMenuId="state.OptionsMenuId" />
  <PaswordDraw ref="paswordraw" :ruleForm="state.passwordList" @SetFormData="editPawrd" />
</template>

<script setup lang="ts">
import Search from './components/Search/UserSearch.vue'
import Table from '@/components/Table.vue'
import Title from '@/components/Title.vue'
import UserDraw from './components/Draw/UserDraw.vue'
import PaswordDraw from './components/Draw/PaswordDraw.vue'
import { Plus } from '@element-plus/icons-vue'
import { AddUserInfo, GetUserInfo, GetRoleList, EditUserInfo, DelUserId, GetUserID, EditUserPaws } from '@/api/user'
import { EpPropMergeType } from 'element-plus/es/utils/vue/props/types'
import { confirmDelete } from '@/utils/messagebox.ts'
interface Button {
  type: EpPropMergeType<
    StringConstructor,
    'primary' | 'danger',
    unknown
  >
  text: string
  fun: Function
}

const userdraw = ref(null)
const paswordraw = ref(null)
const state = reactive({
  searchList: {
    'roleId': '账户类型',
    'status': '状态',
    'phonenumber': '手机号'
  },
  tableData: [],
  total: 20, // 总条数
  currentPage: 1, // 当前页
  pageSize: 8, // 分页大小
  title: '新增用户',
  ruleForm: {
    nickName: '',
    phonenumber: '',
    post: '',
    userName: '',
    roleIds: [],
  },
  passwordList: {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
  OptionsMenuId: [],
  isreadonly: false,
  paswordId: null
})
// 重置密码
const openpawrd = async (_ind, row) => {
  state.paswordId = row.userId
  state.passwordList = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
    paswordraw.value.OpenDialog()
}
// 确认修改
const editPawrd = async (val) => {
  const res = await EditUserPaws({
    userId: state.paswordId,
    password: val.newPassword
  })
  if (res.code == 200) {
    ElMessage({ type: 'success', message: '修改成功' })
  } else {
    ElMessage({ type: 'error', message: '修改失败' })
  }
}
const edit = async (_ind, val) => {
  let res = await GetUserID(val.userId)
  if (res.code == 200) {
    state.title = '编辑用户'
    userdraw.value.OpenDraw()
    state.ruleForm = { ...val, roleIds: res.data.roleIds }
  }

}
const del = (_ind, val) => {
  confirmDelete(async () => {
    const res = await DelUserId(val.userId)
    if (res.code == 200) {
      getInfo()
      ElMessage({ type: 'success', message: '删除成功' })
    } else {
      ElMessage({ type: 'error', message: '删除失败' })
    }
  })
}
const buttons: Button[] = [
  { type: 'primary', text: '重置密码', fun: openpawrd },
  { type: 'primary', text: '编辑', fun: edit },
  { type: 'danger', text: '删除', fun: del },
]

// 获取表单信息
const getInfo = async (params?) => {
  let res = await GetUserInfo({ pageSize: state.pageSize, pageNum: state.currentPage, ...params })
  if (res.code == 200) {
    state.tableData = res.rows
    state.total = res.total
  }
}

const changePage = (val) => {
  state.pageSize = val
  getInfo()
}
const handleSizeChange = (val) => {
  state.currentPage = val
  getInfo()
}
// 新增
const OpenDraw = () => {
  state.title = '新增用户'
  state.ruleForm = {
    nickName: '',
    phonenumber: '',
    post: '',
    userName: '',
    roleIds: [],
  }
  userdraw.value.OpenDraw()
}

// 新增表单
const addForm = async (_val) => {
  if (state.title !== '编辑用户') {
    const res = await AddUserInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '新增成功' })
    } else {
      ElMessage({ type: 'error', message: '新增失败' })
    }
    getInfo()
  } else {
    const res = await EditUserInfo(state.ruleForm)
    if (res.code == 200) {
      ElMessage({ type: 'success', message: '修改成功' })
    } else {
      ElMessage({ type: 'error', message: '修改失败' })
    }
    getInfo()
  }
}
onMounted(async () => {
  const res = await GetRoleList({tenantRole:'0'})
  if (res.code == 200) {
    state.OptionsMenuId = res.data
  }
  getInfo()
})
// 查询
const SearchFn = (val) => {
  getInfo(val)
}
</script>