<template>
    <div class="NoAuth">
      <h1>此账号未开通权限</h1>
      <h3>请联系管理员</h3>
    </div>
</template>

<style lang="scss" scoped>
.NoAuth{
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}
</style>