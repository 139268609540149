import localforage from 'localforage';

export function localGet (key, callback) {
  localforage.getItem(key)
    .then(value => {
      let res
      try {
        res = JSON.parse(value as string)
      } catch (error) { 
        res =  value
      }
      callback && callback(res)
    })
}

export function localSet (key, value) {
  localforage.setItem(key, value)
}

export function localRemove (key) {
  localforage.removeItem(key)
}

// 判断内容是否含有表情字符，现有数据库不支持。
export function hasEmoji (str = '') {
  const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
  return str.match(reg) && str.match(reg).length
}
