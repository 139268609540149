import axios from 'axios';
import localforage from 'localforage';
import { ElMessage } from 'element-plus';

interface requestParams {
  method: string;
  url: string;
  params?: any;
}

axios.interceptors.request.use(
  async (config) => {
    // loading token
    const token = await localforage.getItem('szhlsn_token');
    config.baseURL = `${await localforage.getItem(
      'protocol'
    )}://${await localforage.getItem('IP')}${await localforage.getItem('basePath')}`
    // config.baseURL = ` http://120.24.32.183:8087/`

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?';
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + '=';
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof value[key] !== 'undefined') {
                let params = propName + '[' + key + ']';
                let subPart = encodeURIComponent(params) + '=';
                url += subPart + encodeURIComponent(value[key]) + '&';
              }
            }
          } else {
            url += part + encodeURIComponent(value) + '&';
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      ElMessage('登录失效，请重新登录！');
      localforage.clear();
      // window.location.href = '/auth/Login';
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = ({ method, url, params }: requestParams): Promise<any> => {
  return new Promise((resolve) => {
    let _params;
    let _data;
    // GET POST PUT DELETE
    if (method === 'GET') {
      _params = params;
    } else if (method === 'POST' || method === 'PUT') {
      _data = params;
    } else if (
      method === 'DELETE'
    ) {
      console.log(Array.isArray(params), url, 'uuuurl');

      let ids = '';
      if (Array.isArray(params) && params.length > 0) {
        ids = params.join(',');
      } else if (params) {
        ids = params;
      }
      if (ids) {
        url = `${url}/${ids}`;
      }
    }
    axios
      .request({
        method,
        url,
        params: _params,
        data: _data,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
};

export { request, axios };
