<template>
  <el-dialog v-model="dialogFormVisible" :destroy-on-close="true" class="dialogClss" width="calc(620 / 1920 * 100vw)"
    title="重置密码">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules">
      <el-form-item label="旧密码:" prop="oldPassword" :label-width="formLabelWidth">
        <el-input v-model="ruleForm.oldPassword" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码:" prop="newPassword" :label-width="formLabelWidth">
        <el-input v-model="ruleForm.newPassword" type="password" placeholder="请输入新密码" auto-complete="new-password"
          autocomplete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="再次确认:" prop="newPasswordConfirm" :label-width="formLabelWidth">
        <el-input v-model="ruleForm.newPasswordConfirm" type="password" placeholder="请确认新密码"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
        <el-button type="primary" @click="confirmClick(ruleFormRef)">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { FormRules, FormInstance } from 'element-plus';

const dialogFormVisible = ref(false)
const formLabelWidth = '120px'
const props = defineProps({
  ruleForm: {
    type: Object, default: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    }
  },
  isreadonly: Boolean,
  title: String,
  OptionMenuId: Array
})
const ruleFormRef = ref<FormInstance>()
const validatePass = (_rule, value, callback) => {
  let reg =
    '^(?=.*\\d)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{8,20}';
  if (!new RegExp(reg).test(value)) {
    callback(new Error('密码长度为8-20个字符,须含字母、数字、符号至少2种'));
  } else callback();
};
const validatePass2 = (_rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'));
  } else if (value !== props.ruleForm.newPassword) {
    callback(new Error('两次输入密码不一致!'));
  } else callback();
};
const validatePass3 = (_rule, value, callback) => {
  if (value && value == props.ruleForm.oldPassword) {
    callback(new Error('新旧密码不能一样'));
  } else callback();
};
const rules = reactive<FormRules>({
  oldPassword: [
    { required: true, validator: validatePass, trigger: 'blur' },
  ],
  newPassword: [
    { required: true, validator: validatePass, trigger: 'blur' },
    { validator: validatePass3 },
  ],
  newPasswordConfirm: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { required: true, validator: validatePass2, trigger: 'blur' },
  ],
})
const OpenDialog = () => {
  dialogFormVisible.value = true
  ruleFormRef.value?.resetFields()
}

const emit = defineEmits(["SetFormData"])

const resetForm = (formEl: FormInstance | undefined) => {
  formEl && formEl.resetFields()
  dialogFormVisible.value = false
}

const confirmClick = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log(props.ruleForm,'props.ruleForm');
      
      emit('SetFormData', props.ruleForm)
      resetForm(formEl)
    } else {
      console.log('error submit!', fields)
    }
  })
}
defineExpose({
  OpenDialog,
})
</script>

<style lang="scss" >
.dialogClss {
  border-radius: 10px;

  .el-dialog__header {
    margin-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .el-form-item__label {
    font-size: calc(16 / 1920 * 100vw);
    color: #000;
  }

  .el-dialog__body {

    padding: calc(20 / 1920 * 100vw) calc(17 / 1920 * 100vw) 0 calc(17 / 1920 * 100vw) !important;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    [type^=button] {
      width: 100px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #4886FF;
      background: transparent;
      color: #4886FF;
    }

    .el-button--primary {
      color: #FFF;
      border: 0;
      background: #4886FF;
    }
  }
}
</style>