<template>
    <div>
        <router-view v-slot="{ Component }">
            <transition name="slide-fade">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>
  
<script setup>
</script>
  
<style lang="scss" scoped></style>
  